<template>
    <b-overlay :show='showLoader'>
        <b-card title="Import outlets from excel">
            <b-row>
                <b-col>
                    <b-form-file v-model="file" accept=".xlsx"></b-form-file>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col>
                    <b-button @click="uploadExcelFile">Upload excel</b-button>
                </b-col>
            </b-row>
        </b-card>
    </b-overlay>
</template>
<script>
    import {BCol, BRow, BOverlay, BCard, BFormFile, BButton} from 'bootstrap-vue'
    export default {
        components: {BCol, BRow, BOverlay, BCard, BFormFile, BButton},
        data() {
            return {
                showLoader: false,
                file: null
            }
        },
        methods: {
            uploadExcelFile() {
                if (!this.file) {
                    this.$printWarning('Please select excel file')
                    return
                }

                const thisIns = this
                thisIns.showLoader = true

                const formData = new FormData()
                formData.append('file', this.file)

                thisIns.$http.post('/api/management/v1/outlet/excelImport', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(() => {
                        thisIns.showLoader = false
                        thisIns.$printSuccess('Outlets successfuly imported')
                        thisIns.$router.go(-1)
                    })
                    .catch(error => {
                        thisIns.$printError((error.response) ? error.response.data : error)
                        thisIns.showLoader = false
                    })
            }
        }
    }
</script>